import { Container, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import { RoundedButton } from '../../components/button'
import { CheckoutStepper } from '../../components/stepper'
import { HeroMessage, PageTitle, SectionTitle } from '../../components/typography'
import { useGetOrderCompleteQuery } from '../../hooks/orderHooks'

export default function OrderCompletePage() {
  const params = useParams()
  const { id: orderId } = params
  const { data: res } = useGetOrderCompleteQuery(orderId!)

  if (res && !res.isConfirmed) {
    throw new Error()
  }

  return (
    <>
      <Helmet>
        <title>Visnu EC - ご注文完了</title>
      </Helmet>
      <Container maxWidth="md" sx={{ position: 'relative', pt: '200px', pb: '130px' }}>
        <Stack gap={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle title="Thank you for your order!" subtitle="ご注文完了" />
          <CheckoutStepper currentStep={2} />
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>ご注文完了</SectionTitle>
          </Stack>
          <HeroMessage />
          <Typography fontSize={16} fontWeight={500} textAlign={'center'} lineHeight={3}>
            ご注文いただき、誠にありがとうございます。
            <br />
            商品のご到着までいましばらくお待ち下さい。
            <br />
            ご注文に不明点など御座いましたら、ページ下部のContactよりご連絡ください。
          </Typography>
          <Stack direction={'row'} gap={2}>
            <RoundedButton component={Link} to="/" color="gray">
              商品一覧へ戻る
            </RoundedButton>
            <RoundedButton component={Link} to="/mypage" color="orange">
              マイページへ
            </RoundedButton>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}
