import { ThemeProvider, createTheme } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import App from './App.tsx'
import { StoreProvider } from './Store.tsx'
import AdminRoute from './components/AdminRoute.tsx'
import ProtectedRoute from './components/ProtectedRoute'
import { SnackbarProvider } from './context/snackbarContext.tsx'
import CartPage from './pages/protected/CartPage.tsx'
import ContactCompletePage from './pages/ContactCompletePage.tsx'
import ContactPage from './pages/ContactPage.tsx'
import Error404Page from './pages/Error404Page.tsx'
import HomePage from './pages/HomePage.tsx'
import ProductPage from './pages/ProductPage.tsx'
import CategoryEditPage from './pages/admin/CategoryEditPage.tsx'
import CategoryListPage from './pages/admin/CategoryListPage.tsx'
import DashboardPage from './pages/admin/DashboardPage.tsx'
import OrderListPage from './pages/admin/OrderListPage.tsx'
import ProductEditPage from './pages/admin/ProductEditPage.tsx'
import ProductsListPage from './pages/admin/ProductsListPage.tsx'
import UserEditPage from './pages/admin/UserEditPage.tsx'
import UserListPage from './pages/admin/UserListPage.tsx'
import MyPage from './pages/protected/MyPage.tsx'
import OrderCompletePage from './pages/protected/OrderCompletePage.tsx'
import OrderConfirmationPage from './pages/protected/OrderConfirmationPage.tsx'
import OrderPage from './pages/protected/OrderPage.tsx'
import OrderAdminPage from './pages/admin/OrderAdminPage.tsx'
import CommerceDisclosurePage from './pages/CommerceDisclosurePage.tsx'
import Auth0ProviderWithHistory from './context/Auth0ProviderWithHistory.tsx'

/**
 * それぞれのページのルーティングを設定
 *
 */
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderWithHistory />} errorElement={<Error404Page />}>
      <Route path="/" element={<App />}>
        <Route index element={<HomePage />} />
        <Route path="product/:slug" element={<ProductPage />} />
        <Route path="" element={<ProtectedRoute />}>
          <Route path="order-confirmation" element={<OrderConfirmationPage />} />
          <Route path="order-complete/:id" element={<OrderCompletePage />} />
          <Route path="/order/:id" element={<OrderPage />} />
          <Route path="/mypage" element={<MyPage />} />
          <Route path="cart" element={<CartPage />} />
        </Route>
        {/* Admin Routes */}
        <Route path="" element={<AdminRoute />}>
          <Route path="/admin/dashboard" element={<DashboardPage />} />
          <Route path="/admin/products" element={<ProductsListPage />} />
          <Route path="/admin/product/:id" element={<ProductEditPage />} />
          <Route path="/admin/categorylist" element={<CategoryListPage />} />
          <Route path="/admin/category/:id" element={<CategoryEditPage />} />
          <Route path="/admin/orderlist" element={<OrderListPage />} />
          <Route path="/admin/order/:id" element={<OrderAdminPage />} />
          <Route path="/admin/userlist" element={<UserListPage />} />
          <Route path="/admin/user/:id" element={<UserEditPage />} />
        </Route>
        <Route path="/contact">
          <Route index element={<ContactPage />} />
          <Route path="complete" element={<ContactCompletePage />} />
        </Route>
        <Route path="/commerce-disclosure" element={<CommerceDisclosurePage />} />
      </Route>
    </Route>
  )
)

const queryClient = new QueryClient()

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    allVariants: {
      color: '#3C3C3C'
    },
    fontFamily: [
      'Poppins',
      'Noto Sans JP',
      '-apple-system',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    <StoreProvider>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={muiTheme}>
            <SnackbarProvider>
              <RouterProvider router={router} />
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </StoreProvider>
  </>
)
