import { useMutation, useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

type GetAccessTokenSilentlyType = Parameters<typeof getAPIAccessToken>[0]
type GetAccessTokenWithPopupType = Parameters<typeof getAPIAccessToken>[1]

export const useGetOrderDetailsQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['orders', id],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/orders/:id'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/orders/order-complete/65711ec5d4094433e6ae830e
 */
export const useGetOrderCompleteQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['orders', id],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/orders/order-complete/:id'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

export const useGetOrderDetailsAdminQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['orders', id],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/orders/:id'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

export const useGetOrderHistoryQuery = (
  getAccessTokenSilently: GetAccessTokenSilentlyType,
  getAccessTokenWithPopup: GetAccessTokenWithPopupType
) => {
  return useQuery({
    queryKey: ['order-history'],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/orders/mine'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

export const useGetOrderSummaryQuery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['order-summary'],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/orders/summary'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

export const useGetAllOrdersQuery = (page: number) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['all-orders', page],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/orders/all'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        query: { page }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/orders/65711ec5d4094433e6ae830e/deliver
 */
export const useUpdateOrderDelivery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (body: { orderId: string; trackingNumber: string; shippingCompany: string }) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/orders/:id/deliver'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id: body.orderId },
        body
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/orders/65711ec5d4094433e6ae830e/paymentstatusunpaid
 */
export const useUpdatePaymentStatustoUnpaid = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (orderId: string) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/orders/:id/paymentstatusunpaid'].PUT.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id: orderId }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/orders/65711ec5d4094433e6ae830e/paymentstatuspaid
 */
export const useUpdatePaymentStatustoPaid = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (orderId: string) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/orders/:id/paymentstatuspaid'].PUT.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id: orderId }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}
